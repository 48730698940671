import { NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AuthAction } from '@stsm/auth/models/auth-action';

import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { SignInEmailComponent } from '../sign-in-email/sign-in-email.component';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { SignUpEmailComponent } from '../sign-up-email/sign-up-email.component';

import { SignupDialogService } from './signup-dialog.service';

export enum SignupDialogView {
  SIGNUP,
  SIGNUP_VIA_EMAIL,
  LOGIN,
  LOGIN_VIA_EMAIL,
  RESET_PASSWORD,
}

@UntilDestroy()
@Component({
  selector: 'app-signup-dialog',
  templateUrl: './signup-dialog.component.html',
  styleUrls: ['./signup-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSwitchCase, NgSwitch, SignUpComponent, ResetPasswordComponent, SignInEmailComponent, SignUpEmailComponent],
  host: { 'data-cy': 'signup-dialog' },
})
export class SignupDialogComponent {
  @Input() set view(view: SignupDialogView) {
    this.previousView = this._currentView;
    this._currentView = view;
  }

  get view(): SignupDialogView {
    return this._currentView ?? SignupDialogView.SIGNUP;
  }

  @Input() spawnedBy?: string;

  protected get context(): string | undefined {
    switch (this.previousView) {
      case SignupDialogView.SIGNUP:
        return 'signup_prompt_popup';
      case SignupDialogView.LOGIN:
        return 'login_prompt_popup';
      case SignupDialogView.SIGNUP_VIA_EMAIL:
      case SignupDialogView.LOGIN_VIA_EMAIL:
        return 'back_from_email';
      default:
        return this.spawnedBy;
    }
  }

  protected readonly SignupDialogView: typeof SignupDialogView = SignupDialogView;
  protected readonly AuthAction: typeof AuthAction = AuthAction;

  protected previousView?: SignupDialogView;
  private _currentView?: SignupDialogView;

  constructor(private readonly _signupDialogService: SignupDialogService) {}

  onSuccessfulLoginOrSignup(): void {
    this._signupDialogService.closeSignupModal();
  }

  goToLogin(): void {
    this.view = SignupDialogView.LOGIN;
  }

  goToLoginViaEmail(): void {
    this.view = SignupDialogView.LOGIN_VIA_EMAIL;
  }

  signupWithEmail(): void {
    this.view = SignupDialogView.SIGNUP_VIA_EMAIL;
  }

  goToSignup(): void {
    this.view = SignupDialogView.SIGNUP;
  }

  goToResetPassword(): void {
    this.view = SignupDialogView.RESET_PASSWORD;
  }
}

import { Injectable } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { exhaustMap, Subject, tap } from 'rxjs';

import { LoggerService } from '@stsm/shared/logger/logger.service';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { AnonymousUserService } from '../../shared/services/anonymous-user.service';

import { SignupDialogComponent, SignupDialogView } from './signup-dialog.component';

interface SignupDialogDetails {
  view?: SignupDialogComponent['view'];
  spawnedBy?: SignupDialogComponent['spawnedBy'];
}

@Injectable({ providedIn: 'root' })
export class SignupDialogService {
  private _signupDialogRef: DialogRef<SignupDialogComponent> | undefined = undefined;

  private readonly _signupModalRequest$: Subject<SignupDialogDetails> = new Subject();

  constructor(
    private readonly _platformModalService: PlatformModalService,
    private readonly _loggerService: LoggerService,
    private readonly _anonymousUserService: AnonymousUserService,
  ) {
    this._signupModalRequest$
      .pipe(
        exhaustMap(({ view, spawnedBy }: SignupDialogDetails) => {
          this._signupDialogRef = this._platformModalService.create({
            component: SignupDialogComponent,
            data: { view, spawnedBy },
          });

          return this._signupDialogRef.afterClosed().pipe(tap(() => (this._signupDialogRef = undefined)));
        }),
      )
      .subscribe();
  }

  openSignupModal({
    requestedUrl = [],
    view = SignupDialogView.SIGNUP,
    spawnedBy,
  }: SignupDialogDetails & { requestedUrl?: string | UrlSegment[] } = {}): void {
    this._anonymousUserService.setRequestedUrl(
      Array.isArray(requestedUrl) ? requestedUrl.map((segment: UrlSegment) => segment.path).join('/') : requestedUrl,
    );

    this._signupModalRequest$.next({ view, spawnedBy });
  }

  closeSignupModal(): void {
    if (!this._signupDialogRef) {
      this._loggerService.warn('No SignupDialog to close!');

      return;
    }

    this._signupDialogRef.dismiss();
  }
}

<ng-container [ngSwitch]="view">
  <ng-container *ngSwitchCase="SignupDialogView.SIGNUP">
    <app-sign-up
      [isDialogMode]="true"
      [context]="context"
      (goToLoginOrSignup)="goToLogin()"
      (loginOrSignupWithEmail)="signupWithEmail()"
      (successfulLoginOrSignup)="onSuccessfulLoginOrSignup()"
    />
  </ng-container>

  <ng-container *ngSwitchCase="SignupDialogView.SIGNUP_VIA_EMAIL">
    <app-sign-up-email
      [isDialogMode]="true"
      [context]="context"
      [hasBackButton]="this.spawnedBy !== 'blocking_banner'"
      (back)="goToSignup()"
      (successfulSignup)="onSuccessfulLoginOrSignup()"
    />
  </ng-container>

  <ng-container *ngSwitchCase="SignupDialogView.LOGIN">
    <app-sign-up
      [isDialogMode]="true"
      [context]="context"
      [action]="AuthAction.LOGIN"
      (goToLoginOrSignup)="goToSignup()"
      (loginOrSignupWithEmail)="goToLoginViaEmail()"
      (successfulLoginOrSignup)="onSuccessfulLoginOrSignup()"
    />
  </ng-container>

  <ng-container *ngSwitchCase="SignupDialogView.LOGIN_VIA_EMAIL">
    <app-sign-in-email
      [isDialogMode]="true"
      (back)="goToLogin()"
      (goToResetPassword)="goToResetPassword()"
      (successfulSignIn)="onSuccessfulLoginOrSignup()"
    />
  </ng-container>

  <ng-container *ngSwitchCase="SignupDialogView.RESET_PASSWORD">
    <app-reset-password [isDialogMode]="true" (goToLogin)="goToLoginViaEmail()" />
  </ng-container>
</ng-container>
